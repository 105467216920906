import {
  AppAction,
  Application
} from '../apis/eols-app-link/eols-app-link.constants';
import { LANGUAGE_KEY } from '../translations/message.constants';
import {
  AssessmentTypeDto,
  AssignmentType
} from '../apis/eols-assessment-service/eols-assessment-service.dtos';
import { SyllabusItemTypeDto } from '../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';
import { AppLinkRedirectErrorMessage } from '../pages/app-link-redirect/app-link-redirect.constants';

export enum AnalyticsAction {
  COURSE_BUILT = 'COURSE_BUILT',
  RETURN_TO_COURSE_BUILDER_CLICK = 'RETURN_TO_COURSE_BUILDER_CLICK',
  COURSE_SETTINGS_UPDATED = 'COURSE_SETTINGS_UPDATED',
  RESOURCE_FILTER_ACTIVATED = 'RESOURCE_FILTER_ACTIVATED',
  SYLLABUS_ITEM_ACTION_CLICK = 'SYLLABUS_ITEM_ACTION_CLICK',
  NAV_ITEM_CLICK = 'NAV_ITEM_CLICK',
  COURSE_PLAN_TAB_CLICK = 'COURSE_PLAN_TAB_CLICK',
  SYLLABUS_ITEM_TITLE_CLICK = 'SYLLABUS_ITEM_TITLE_CLICK',
  SYLLABUS_ITEM_DATES_CLICK = 'SYLLABUS_ITEM_DATES_CLICK',
  SYLLABUS_ITEM_GRADE_CLICK = 'SYLLABUS_ITEM_GRADE_CLICK',
  FOLDER_RENAMED = 'FOLDER_RENAMED',
  VIEW_ALL_ATTEMPTS_CLICK = 'VIEW_ALL_ATTEMPTS_CLICK',
  STUDENT_SIMULATION_PERFORMANCE_MODAL_OPENED = 'STUDENT_SIMULATION_PERFORMANCE_MODAL_OPENED',
  LOGIN_LAUNCH = 'LOGIN_LAUNCH',
  APP_LINK_LAUNCH = 'APP_LINK_LAUNCH',
  APP_LINK_ERROR = 'APP_LINK_ERROR',
  APP_LINK_OUT = 'APP_LINK_OUT',
  CREATE_A_QUIZ_CLICK = 'CREATE_A_QUIZ_CLICK',
  RESUME_QUIZ_CLICK = 'RESUME_QUIZ_CLICK',
  VIEW_RESULTS_CLICK = 'VIEW_RESULTS_CLICK',
  QUIZ_ME_ON_THIS_TOPIC_CLICK = 'QUIZ_ME_ON_THIS_TOPIC_CLICK',
  CATALOG_ITEM_ADDED = 'CATALOG_ITEM_ADDED',
  CATALOG_ITEM_TITLE_CLICK = 'CATALOG_ITEM_TITLE_CLICK',
  EAQ_SELF_STUDY_CLICK = 'EAQ_SELF_STUDY_CLICK',
  HELP_LINK_CLICK = 'HELP_LINK_CLICK',
  SYLLABUS_ITEM_DRAGGED = 'SYLLABUS_ITEM_DRAGGED',
  COURSE_SWITCH_LINK_CLICK = 'COURSE_SWITCH_LINK_CLICK',
  COURSE_SWITCH_CLICK = 'COURSE_SWITCH_CLICK',
  BATCH_EDIT_TOGGLE_CLICK = 'BATCH_EDIT_TOGGLE_CLICK',
  DRAG_DROP_TOGGLE_CLICK = 'DRAG_DROP_TOGGLE_CLICK',
  BULK_EDITOR_APPLY_TO_ALL_CLICK = 'BULK_EDITOR_APPLY_TO_ALL_CLICK',
  BULK_EDITOR_SORT_BY_CHANGE = 'BULK_EDITOR_SORT_BY_CHANGE',
  BULK_EDITOR_SINGLE_FIELD_UPDATE = 'BULK_EDITOR_SINGLE_FIELD_UPDATE',
  BULK_EDITOR_CANCEL = 'BULK_EDITOR_CANCEL',
  BULK_EDITOR_RESET_ALL = 'BULK_EDITOR_RESET_ALL',
  BULK_EDITOR_SAVE = 'BULK_EDITOR_SAVE',
  AUTHESS_HEALTH_CHECK_SUCCESS = 'AUTHESS_HEALTH_CHECK_SUCCESS',
  AUTHESS_HEALTH_CHECK_FAIL = 'AUTHESS_HEALTH_CHECK_FAIL',
  AUTHESS_HEALTH_CHECK_ASSIGNMENT_FIXED = 'AUTHESS_HEALTH_CHECK_ASSIGNMENT_FIXED',
  API_ERROR = 'API_ERROR',
  EBOOK_CLICK = 'EBOOK_CLICK',
  EBOOK_PURCHASE_BINDER_READY_VERSION_CLICK = 'EBOOK_PURCHASE_BINDER_READY_VERSION_CLICK',
  TOGGLE_ALL_FOLDERS_CLICK = 'TOGGLE_ALL_FOLDERS_CLICK',
  TRAINING_LINK_CLICKED = 'TRAINING_LINK_CLICKED',
  MISSING_MAPPINGS = 'MISSING_MAPPINGS',
  OSMOSIS_VIDEO_PLAYER_EVENT = 'OSMOSIS_VIDEO_PLAYER_EVENT',
  MY_EVOLVE_LINK_CLICK = 'MY_EVOLVE_LINK_CLICK',
  SKILL_PLAYER_NEW_TAB_CLICK = 'SKILL_PLAYER_NEW_TAB_CLICK',
  EBOOK_ASSIGNMENT_SAVE_CLICK = 'EBOOK_ASSIGNMENT_SAVE_CLICK',
  SET_DEFAULT_TAB_CLICK = 'SET_DEFAULT_TAB_CLICK',
  STUDENT_LAUNCH_WARNING_MODAL_OPENED = 'STUDENT_LAUNCH_WARNING_MODAL_OPENED',
  STUDENT_LAUNCH_BY_INSTRUCTOR = 'STUDENT_LAUNCH_BY_INSTRUCTOR',
  SYLLABUS_ITEMS_WITH_SAME_ASSIGNMENT_FOUND = 'SYLLABUS_ITEMS_WITH_SAME_ASSIGNMENT_FOUND',
  AI_CHAT_CITATION_CLICK = 'AI_CHAT_CITATION_CLICK',
  AI_CHAT_CITATION_LINK_CLICK = 'AI_CHAT_CITATION_LINK_CLICK',
  AI_CHAT_EDIT_CLICK = 'AI_CHAT_EDIT_CLICK',
  AI_CHAT_EDIT_CONFIRM_CLICK = 'AI_CHAT_EDIT_CONFIRM_CLICK',
  AI_CHAT_CHAT_CLICK = 'AI_CHAT_CHAT_CLICK',
  AI_CHAT_NEW_CHAT_CLICK = 'AI_CHAT_NEW_CHAT_CLICK',
  AI_CHAT_RETURN_CLICK = 'AI_CHAT_RETURN_CLICK',
  AI_CHAT_CANCEL_FEEDBACK_CLICK = 'AI_CHAT_CANCEL_FEEDBACK_CLICK',
  AI_CHAT_FEEDBACK_DIMENSION_CLICK = 'AI_CHAT_FEEDBACK_DIMENSION_CLICK',
  AI_CHAT_FEEDBACK_FACE_CLICK = 'AI_CHAT_FEEDBACK_FACE_CLICK',
  AI_CHAT_FEEDBACK_SUBMIT = 'AI_CHAT_FEEDBACK_SUBMIT',
  AI_CHAT_COPY_CLICK = 'AI_CHAT_COPY_CLICK',
  AI_CHAT_VIDEO_LINK_CLICK = 'AI_CHAT_VIDEO_LINK_CLICK',
  AI_CHAT_SIDEBAR_FEEDBACK_LINK_CLICK = 'AI_CHAT_SIDEBAR_FEEDBACK_LINK_CLICK',
  AI_CHAT_HOW_IT_WORKS_LINK_CLICK = 'AI_CHAT_HOW_IT_WORKS_LINK_CLICK',
  AI_CHAT_PROMPT_SUBMITTED = 'AI_CHAT_PROMPT_SUBMITTED',
  AI_CHAT_ERROR = 'AI_CHAT_ERROR',
  AI_CHAT_CITATION_EXPAND_TOGGLE = 'AI_CHAT_CITATION_EXPAND_TOGGLE',
  AI_CHAT_BOT_RESPONSE_COMPLETE = 'AI_CHAT_BOT_RESPONSE_COMPLETE',
  AI_CHAT_ACTION_WHILE_STREAMING_ERROR = 'AI_CHAT_ACTION_WHILE_STREAMING_ERROR',
  AI_CHAT_TOP_NAV_CLICK = 'AI_CHAT_TOP_NAV_CLICK',
}

export enum AnalyticsRef {
  SIDEBAR = 'SIDEBAR',
  SYLLABUS = 'SYLLABUS',
  BATCH_ACTION_BAR = 'BATCH_ACTION_BAR',
}

export enum AnalyticsFilterType {
  EBOOK_FILTER = 'EBOOK_FILTER',
  STATUS_FILTER = 'STATUS_FILTER',
  TYPE_FILTER = 'TYPE_FILTER',
  GRADING_FILTER = 'GRADING_FILTER',
  HESI_FOCUS_CHAPTER = 'HESI_FOCUS_CHAPTER',
}

export type AnalyticsActionProps = {
  action: AnalyticsAction.FOLDER_RENAMED
    | AnalyticsAction.LOGIN_LAUNCH
    | AnalyticsAction.EAQ_SELF_STUDY_CLICK
    | AnalyticsAction.RETURN_TO_COURSE_BUILDER_CLICK
    | AnalyticsAction.BULK_EDITOR_RESET_ALL
    | AnalyticsAction.BULK_EDITOR_SAVE
    | AnalyticsAction.AUTHESS_HEALTH_CHECK_FAIL
    | AnalyticsAction.MY_EVOLVE_LINK_CLICK;
  props: null;
} | {
  action: AnalyticsAction.TOGGLE_ALL_FOLDERS_CLICK;
  props: {
    hasCollapsedFolderIds: boolean;
  };
} | {
  action: AnalyticsAction.EBOOK_CLICK
    | AnalyticsAction.EBOOK_PURCHASE_BINDER_READY_VERSION_CLICK;
  props: {
    vbId: string;
    ref: string;
  };
} | {
  action: AnalyticsAction.API_ERROR;
  props: {
    status: string;
    action: string;
    url: string;
    method: string;
  };
} | {
  action: AnalyticsAction.APP_LINK_ERROR;
  props: {
    type: AppLinkRedirectErrorMessage;
    status: string;
    url: string;
    method: string;
  };
} | {
  action: AnalyticsAction.AUTHESS_HEALTH_CHECK_SUCCESS;
  props: {
    assignmentIds: string;
  };
} | {
  action: AnalyticsAction.AUTHESS_HEALTH_CHECK_ASSIGNMENT_FIXED;
  props: {
    assignmentId: number;
  };
} | {
  action: AnalyticsAction.BULK_EDITOR_APPLY_TO_ALL_CLICK;
  props: {
    type: string;
    grading?: string;
    pushType?: string;
    weeks?: number;
    days?: number;
    hours?: number;
    minutes?: number;
  };
} | {
  action: AnalyticsAction.BULK_EDITOR_SORT_BY_CHANGE;
  props: {
    value: string;
  };
} | {
  action: AnalyticsAction.BULK_EDITOR_SINGLE_FIELD_UPDATE;
  props: {
    type: string;
  };
} | {
  action: AnalyticsAction.BULK_EDITOR_CANCEL;
  props: {
    ref: string;
  };
} | {
  action: AnalyticsAction.COURSE_BUILT;
  props: {
    isRebuild: boolean;
    isDeepLinkCourseCopy: boolean;
  };
} | {
  action: AnalyticsAction.APP_LINK_OUT
    | AnalyticsAction.APP_LINK_LAUNCH;
  props: {
    linkType: AppAction;
    srcApp: Application;
    targetApp: Application;
  };
} | {
  action: AnalyticsAction.CREATE_A_QUIZ_CLICK;
  props: {};
} | {
  action: AnalyticsAction.COURSE_PLAN_TAB_CLICK;
  props: {
    tab: LANGUAGE_KEY.ALL_ITEMS | LANGUAGE_KEY.UPCOMING_ASSIGNMENTS | LANGUAGE_KEY.PAST_DUE_ASSIGNMENTS;
  };
} | {
  action: AnalyticsAction.COURSE_SETTINGS_UPDATED;
  props: {
    isLockStatusChanged: boolean;
    isNameChanged: boolean;
  };
} | {
  action: AnalyticsAction.RESOURCE_FILTER_ACTIVATED;
  props: {
    type: AnalyticsFilterType;
    option: string; // TODO: Deprecated - remove once New Relic dashboards are updated
    optionValue: string;
    optionDisplay: string;
    chapterId?: string; // Chapter id is optional to help distinguish a chapter node filter from a book node filter
    bookId?: string; // Book id is optional to help identify the book that a chapter filter is within
  };
} | {
  action: AnalyticsAction.VIEW_ALL_ATTEMPTS_CLICK;
  props: {
    assignmentType: AssignmentType;
  };
} | {
  action: AnalyticsAction.STUDENT_SIMULATION_PERFORMANCE_MODAL_OPENED;
  props: {};
} | {
  action: AnalyticsAction.SYLLABUS_ITEM_DRAGGED
    | AnalyticsAction.SYLLABUS_ITEM_GRADE_CLICK
    | AnalyticsAction.SYLLABUS_ITEM_DATES_CLICK
    | AnalyticsAction.SYLLABUS_ITEM_TITLE_CLICK;
  props: {
    syllabusItemType: SyllabusItemTypeDto;
    activeTab: LANGUAGE_KEY.ALL_ITEMS | LANGUAGE_KEY.UPCOMING_ASSIGNMENTS | LANGUAGE_KEY.PAST_DUE_ASSIGNMENTS;
  };
} | {
  action: AnalyticsAction.CATALOG_ITEM_TITLE_CLICK;
  props: {
    contentId: string;
    catalogItemType: string;
    catalogItemSubType: string;
  };
} | {
  action: AnalyticsAction.CATALOG_ITEM_ADDED;
  props: {
    contentId: string;
    catalogItemType: string;
    isBulkAdd: boolean;
    catalogItemSubType: string;
  };
} | {
  action: AnalyticsAction.COURSE_SWITCH_CLICK;
  props: {
    targetApp: string;
  };
} | {
  action: AnalyticsAction.COURSE_SWITCH_LINK_CLICK;
  props: {
    ref: 'COURSE_PLAN' | 'USER_LOGOUT';
  };
} | {
  action: AnalyticsAction.HELP_LINK_CLICK;
  props: {
    application: string;
    link: string;
  };
} | {
  action: AnalyticsAction.BATCH_EDIT_TOGGLE_CLICK
    | AnalyticsAction.DRAG_DROP_TOGGLE_CLICK;
  props: {
    value: boolean;
    ref: AnalyticsRef;
  };
} | {
  action: AnalyticsAction.TRAINING_LINK_CLICKED;
  props: {
    application: string;
  };
} | {
  action: AnalyticsAction.OSMOSIS_VIDEO_PLAYER_EVENT;
  props: {
    type: string;
    activeChatId: string;
    videoId: string;
  };
} | {
  action: AnalyticsAction.SKILL_PLAYER_NEW_TAB_CLICK;
  props: {
    assignmentId: string;
    skillBlockId: string;
    syllabusItemId: string;
  };
} | {
  action: AnalyticsAction.STUDENT_LAUNCH_WARNING_MODAL_OPENED;
  props: {};
} | {
  action: AnalyticsAction.STUDENT_LAUNCH_BY_INSTRUCTOR;
  props: {
    targetStudentId: number;
  };
} | {
  action: AnalyticsAction.EBOOK_ASSIGNMENT_SAVE_CLICK;
  props: {
    assignmentId: number;
    notDefaultChapterIds: string[];
  };
} | {
  action: AnalyticsAction.SET_DEFAULT_TAB_CLICK;
  props: {
    defaultTab: LANGUAGE_KEY.ALL_ITEMS | LANGUAGE_KEY.UPCOMING_ASSIGNMENTS | LANGUAGE_KEY.PAST_DUE_ASSIGNMENTS;
  };
} | {
  action: AnalyticsAction.SYLLABUS_ITEMS_WITH_SAME_ASSIGNMENT_FOUND;
  props: {
    syllabusItemIds: string[];
  };
} | {
  action: AnalyticsAction.RESUME_QUIZ_CLICK | AnalyticsAction.VIEW_RESULTS_CLICK;
  props: {
    assessmentId: number;
    assessmentType: AssessmentTypeDto;
    assessmentGoal: number;
    assessmentTopicVtwIds: string[];
  };
} | {
  action: AnalyticsAction.QUIZ_ME_ON_THIS_TOPIC_CLICK;
  props: {
    assessmentTopicVtwId: string;
    selectedIsbn: string;
  };
} | {
  action: AnalyticsAction.AI_CHAT_CITATION_CLICK;
  props: {
    type: string;
    resourceType: string;
    citationId: number;
    entryId: number;
  };
} | {
  action: AnalyticsAction.AI_CHAT_CITATION_LINK_CLICK;
  props: {
    type: string;
    resourceType: string;
    citationId: number;
    entryId: number;
    pageStart: number;
    eeoISBN: string;
  };
} | {
  action: AnalyticsAction.AI_CHAT_EDIT_CLICK;
  props: {
    targetChatId: number;
    targetChatTitle: string;
    isTargetActive: boolean;
  };
} | {
  action: AnalyticsAction.AI_CHAT_CHAT_CLICK
    | AnalyticsAction.AI_CHAT_EDIT_CONFIRM_CLICK;
  props: {
    targetChatId: number;
    targetChatTitle: string;
    isTargetActive: boolean;
    isStreaming: boolean;
  };
} | {
  action: AnalyticsAction.AI_CHAT_RETURN_CLICK
    | AnalyticsAction.AI_CHAT_CANCEL_FEEDBACK_CLICK;
  props: null;
} | {
  action: AnalyticsAction.AI_CHAT_NEW_CHAT_CLICK;
  props: {
    isStreaming: boolean;
  };
} | {
  action: AnalyticsAction.AI_CHAT_FEEDBACK_FACE_CLICK;
  props: {
    rating: number;
    entryId: number;
  };
} | {
  action: AnalyticsAction.AI_CHAT_FEEDBACK_SUBMIT;
  props: {
    rating: number;
    entryId: number;
    isFaceClick: boolean;
  };
} | {
  action: AnalyticsAction.AI_CHAT_FEEDBACK_DIMENSION_CLICK;
  props: {
    rating: number;
    name: string;
    isActiveWhenClicked: boolean;
    entryId: number;
  };
} | {
  action: AnalyticsAction.AI_CHAT_COPY_CLICK | AnalyticsAction.AI_CHAT_PROMPT_SUBMITTED;
  props: {
    entryId: number;
    idx: number;
  };
} | {
  action: AnalyticsAction.AI_CHAT_VIDEO_LINK_CLICK;
  props: {
    entryId: number;
    recommendationId: number;
    videoId: string;
  };
} | {
  action: AnalyticsAction.AI_CHAT_SIDEBAR_FEEDBACK_LINK_CLICK
    | AnalyticsAction.AI_CHAT_HOW_IT_WORKS_LINK_CLICK;
  props: null;
} | {
  action: AnalyticsAction.AI_CHAT_ERROR;
  props: {
    type: string;
  };
} | {
  action: AnalyticsAction.AI_CHAT_CITATION_EXPAND_TOGGLE;
  props: {
    statusAfterToggle: string;
  };
} | {
  action: AnalyticsAction.AI_CHAT_BOT_RESPONSE_COMPLETE;
  props: {
    millisecondsToComplete: number;
    millisecondsToCompleteTotal: number;
    entryId: number;
    idx: number;
  };
} | {
  action: AnalyticsAction.AI_CHAT_ACTION_WHILE_STREAMING_ERROR;
  props: {
    actionRef: string;
  };
} | {
  action: AnalyticsAction.AI_CHAT_TOP_NAV_CLICK;
  props: {
    targetPage: string;
  };
};
