import React, { Component } from 'react';
import {
  connect,
  ConnectedProps
} from 'react-redux';
import {
  get,
} from 'lodash';
import {
  ELSCommonUIConstants,
  ELSPendoService,
  ELSTokenHelper,
} from '@els/els-ui-common-react';
import {
  ELSAnalyticsRegistrarComponent,
  ELSPerformanceAnalyticsRegistrarProps
} from '@els/els-component-shared-ts-react';
import { studySelectors } from '../../redux/student-study/studentStudy.selectors';
import { AppConstants } from '../app/app.constants';
import {
  convertIsbnsToNormalizedString,
  getApplicationContext
} from '../../utilities/app.utilities';
import {
  isInstructor,
  isStudent
} from '../../utilities/common.utilities';

const mapStateToProps = state => ({
  course: studySelectors.getCourse(state),
  isbns: studySelectors.getIsbns(state),
  userId: studySelectors.getUserId(state),
  userRole: studySelectors.getRoleId(state),
  evolveUser: studySelectors.getEvolveUser(state),
  evolveProducts: studySelectors.getEvolveProducts(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type AnalyticsRegistrarState = {
  pendoScriptInjected: boolean;
}

export type AnalyticsRegistrarProps = PropsFromRedux;

export class AnalyticsRegistrarComponent extends Component<AnalyticsRegistrarProps, AnalyticsRegistrarState> {

  constructor(props) {
    super(props);
    this.state = {
      pendoScriptInjected: !!window.pendo && !!window.pendo.initialize,
    };
  }

  componentDidMount() {
    if (!this.state.pendoScriptInjected) {
      ELSPendoService.injectScript(AppConstants.PENDO_API_KEY).then(() => {
        this.setState({ pendoScriptInjected: true });
      });
    }
  }

  getEvolveUserEmail = () => {
    const { evolveUser } = this.props;
    if (!evolveUser) {
      return null;
    }
    const evolveUserData = JSON.parse(evolveUser.userData);
    if (!evolveUserData || !evolveUserData.email) {
      return null;
    }
    return evolveUserData.email;
  }

  render() {
    const {
      isbns,
      userRole,
      course,
      userId,
      evolveUser
    } = this.props;

    if (!isbns || !course || !this.state.pendoScriptInjected) {
      return <></>;
    }

    if (!evolveUser) {
      return <></>;
    }

    const institution = course ? course.institution : null;
    const courseName = course ? course.courseName : null;

    const props: ELSPerformanceAnalyticsRegistrarProps = {
      isbn: convertIsbnsToNormalizedString(isbns),
      userRole,
      appName: ELSCommonUIConstants.appIds.STUDENT_STUDY,
      productName: get(window, 'pageData.page.productName', ''),
      businessUnit: get(window, 'pageData.page.businessUnit', ''),
      visitorId: null,
      pageEnvironment: get(window, 'pageData.page.environment', ''),
      courseId: course.id.toString(),
      accountName: institution ? institution.name : null,
      accountId: institution ? institution.id.toString() : null,
      evolveUserId: evolveUser ? evolveUser.userName.toString() : null,
      eolsUserId: userId,
      shouldInjectPendoScript: false,
      eolsUserEmail: ELSTokenHelper.getUserEmail(),
      evolveUserEmail: this.getEvolveUserEmail(),
      isStudent: isStudent(userRole),
      isInstructor: isInstructor(userRole),
      isIncludeAdobeEducationObject: true,
      courseName,
      departmentId: '',
      departmentName: '',
      application: getApplicationContext()
    };

    return <ELSAnalyticsRegistrarComponent {...props} />;
  }
}

const AnalyticsRegistrar = connector(AnalyticsRegistrarComponent);

export default AnalyticsRegistrar;
