import {
  ELSToastService,
  ELSWithToastService
} from '@els/els-component-toast-react';
import React, { ReactNode } from 'react';
import {
  isNil,
  orderBy,
} from 'lodash';
import { ELSTokenHelper } from '@els/els-ui-common-react';
import {
  ChatDto,
  ChatEntryAuthorDto,
  ChatEntryDto,
  ChatEntryTypeDto,
  ChatModelNameDto,
  ChatReferenceClassDto,
  ChatReferenceContentPropsDto,
  ChatReferenceDto,
  ChatReferenceWithParsedContentProps,
  ChatResourceTypeDto
} from '../../apis/florence-facade/florence-facade.dtos';
import { BASE_TOAST_CONFIG } from '../../constants/toast.constants';
import { FeatureFlagsGroupedDto } from '../../apis/eols-features-api/eols-features-api.dtos';
import {
  getFeatureFlagGroups,
  getGroupFeatureFlagWithFallbackToGlobal
} from '../../utilities/featureFlag.utilities';
import { FEATURE_FLAG } from '../../apis/eols-features-api/eols-features-api.constants';
import {
  AnalyticsAction,
  AnalyticsActionProps
} from '../../models/analytics.models';
import { apStyleTitleCase } from '../../utilities/title-case.utilities';
import {
  TEMP_BOT_CHAT_ID,
} from './ai-chat.constants';

export const getCitations = (entry: ChatEntryDto): ChatReferenceDto[] => {
  if (!entry || !entry.references || !entry.references.length) {
    return null;
  }

  return entry.references
    .filter((reference) => {
      return reference.isVisible
        && reference.referenceClass === ChatReferenceClassDto.CITATION;
    }) as ChatReferenceDto[];

};

export const getParsedContentProps = (citation: ChatReferenceDto): ChatReferenceContentPropsDto => {
  if (!citation || !citation.contentProps) {
    return null;
  }
  let _contentProps;
  try {
    _contentProps = JSON.parse(citation.contentProps);
  } catch (e) {
    _contentProps = null;
  }
  return _contentProps;
};

export const getOsmosisVideoId = (reference: ChatReferenceDto) => {
  if (!reference) {
    return null;
  }

  const uriPrefix = 'https://osmosis.org/learn/';
  const contentProps = getParsedContentProps(reference);

  let resourceUri;
  if (reference.resourceUri) {
    resourceUri = reference.resourceUri;
  } else if (contentProps.source_id) {
    resourceUri = contentProps.source_id;
  }

  if (!resourceUri) {
    return null;
  }

  if (!resourceUri.includes(uriPrefix)) {
    return null;
  }
  return resourceUri.replace(uriPrefix, '');
};
export const getRecommendations = (entry: ChatEntryDto): ChatReferenceDto[] => {
  if (!entry || !entry.references || !entry.references.length) {
    return null;
  }

  return entry.references.filter((reference) => {
    const videoId = getOsmosisVideoId(reference);

    return reference.isVisible
      && reference.referenceClass === ChatReferenceClassDto.RECOMMENDATION
      && reference.resourceType === ChatResourceTypeDto.VIDEO
      && videoId;
  }) as ChatReferenceDto[];
};

export const toastApiError = (toastService: ELSToastService) => {

  toastService.openToast({
    ...BASE_TOAST_CONFIG,
    component: (
      <div>
        <p>Request Error</p>
        <p>Please refresh the page and try again. If the problem persists please contact your administrator.</p>
      </div>
    ),
    type: ELSWithToastService.types.NEGATIVE
  });
};

export const toastActiveStreamingError = (
  toastService: ELSToastService,
  trackAction: (props: AnalyticsActionProps) => void,
  actionRef: string
) => {

  trackAction({
    action: AnalyticsAction.AI_CHAT_ACTION_WHILE_STREAMING_ERROR,
    props: {
      actionRef
    }
  });

  toastService.openToast({
    ...BASE_TOAST_CONFIG,
    component: (
      <div>
        <p>This action cannot be performed while the current prompt is being answered</p>
      </div>
    ),
    type: ELSWithToastService.types.NEGATIVE
  });
};

export const getEbookCitationPathsOLD = (citation: ChatReferenceDto): string[] => {
  if (citation.resourceType !== ChatResourceTypeDto.EBOOK) {
    return [];
  }
  if (!citation || !citation.uri) {
    return [];
  }
  const chatperSeperator = 'chapter:';
  const paths = citation.uri.split(chatperSeperator).reduce((acc, cur) => {
    if (!cur) {
      return acc;
    }
    return [
      ...acc,
      `${chatperSeperator}${cur}`
    ];
  }, []);
  return paths.map((path) => {
    return path.split('|').filter((part) => {
      return !!part;
    }).map((part) => {
      return apStyleTitleCase(part.toLowerCase(), null);
    }).map((part) => {
      return part
        .replace('Chapter:', 'Chapter: ')
        .replace('Section:', 'Section: ')
        .replace('Subsection:', 'Subsection: ')
        .replace('Step:', 'Step: ');
    })
      .join(' | ');
  });
};
export const getCitationDisplayOLD = (citation: ChatReferenceDto): ReactNode => {
  if (citation.resourceType === ChatResourceTypeDto.EBOOK) {
    const paths = getEbookCitationPathsOLD(citation);
    return (
      <div>
        <div>{citation.resourceTitle}</div>
        <div>
          {paths && paths.length > 0 && paths.map((path) => {
            return (
              <div
                key={path}
              >
                {path}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return citation.title;
};

export const getIsDirectAccess = (
  featureFlagsGrouped: FeatureFlagsGroupedDto[],
  courseSectionId: string,
  isbns: string[],
): boolean => {

  if (!featureFlagsGrouped || !courseSectionId || !isbns || !isbns.length) {
    return false;
  }

  const featureIsbns = getFeatureFlagGroups(featureFlagsGrouped, FEATURE_FLAG.IS_DIRECT_CHATBOT_ISBN);

  if (!featureIsbns || !featureIsbns.length) {
    return false;
  }

  return featureIsbns.some((isbn) => {
    return isbns.some((_isbn) => {
      return _isbn === isbn;
    });
  });
};

export const extractNumber = (str: string): number => {
  const matches = str.match(/\((\d+)\)[^()]*$/);
  return matches ? parseInt(matches[1], 10) : null;
};
export const getNextChatTitle = (title: string, chats: ChatDto[]): string => {
  if (!chats || !chats.length || !title) {
    return title;
  }

  const numbers = chats.filter((chat) => {
    return chat.title && chat.title.startsWith(title);
  }).reduce((acc, chat) => {
    const number = extractNumber(chat.title);
    return [
      ...acc,
      isNil(number) ? 0 : number
    ];
  }, []).sort((a, b) => {
    if (isNil(a) || isNil(b)) {
      return 0;
    }
    return a - b;
  }).reverse();

  if (!numbers || !numbers.length) {
    return title;
  }

  return `${title} (${numbers[0] + 1})`;
};

export const getLLMModel = (featureFlagsGrouped: FeatureFlagsGroupedDto[], courseSectionId: string): ChatModelNameDto => {
  const featureFlag = getGroupFeatureFlagWithFallbackToGlobal(
    featureFlagsGrouped,
    FEATURE_FLAG.FLORENCE_CHAT_MODEL,
    courseSectionId
  );
  if (!featureFlag) {
    return ChatModelNameDto.claude;
  }
  if (featureFlag.featureValue === 'RANDOM') {
    const num = Math.random();
    if (num > 0.5) {
      return ChatModelNameDto.gpt35;
    }
    return ChatModelNameDto.claude;
  }
  return featureFlag.featureValue as ChatModelNameDto;
};

export const isShowFeedback = (entry: ChatEntryDto) => {
  return entry.author === ChatEntryAuthorDto.BOT
    && entry.id
    && entry.id !== TEMP_BOT_CHAT_ID
    && entry.entryType === ChatEntryTypeDto.MESSAGE;
};

export const getSortedChats = (chats: ChatDto[]): ChatDto[] => {
  if (!chats) {
    return null;
  }
  return orderBy(chats, (chat) => {
    return chat.id;
  }, 'desc');
};

export const getAfterChapterString = (citation: ChatReferenceWithParsedContentProps) => {
  if (!citation || !citation._contentProps || !citation._contentProps.bread_crumbs) {
    return null;
  }

  const index = citation._contentProps.bread_crumbs.findIndex(breadcrumb => breadcrumb.type === 'CHAPTER');
  const parts = index === -1 ? citation._contentProps.bread_crumbs : citation._contentProps.bread_crumbs.slice(index + 1);

  if (!parts || !parts.length) {
    return null;
  }

  const defaultDelineator = '; ';
  const deliniaterMap = {
    SECTION: defaultDelineator,
    SUBSECTION: ' - ',
  };

  return parts.reduce((acc, breadcrumb, idx, arr) => {
    if (!breadcrumb.title) {
      return acc;
    }
    const delineator = idx === arr.length - 1 ? '' : deliniaterMap[breadcrumb.type] || defaultDelineator;
    return `${acc}${breadcrumb.title}${delineator}`;
  }, '');
};

export const getCitationGroups = (citations: ChatReferenceDto[]): Record<string, ChatReferenceWithParsedContentProps[]> => {
  if (!citations || !citations.length) {
    return {};
  }
  return citations.reduce((acc, cur) => {

    if (cur.resourceType !== ChatResourceTypeDto.EBOOK) {
      return acc;
    }

    const withParsedContentProps = {
      ...cur,
      _contentProps: getParsedContentProps(cur)
    };

    if (!withParsedContentProps._contentProps) {
      return acc;
    }

    const isbn = withParsedContentProps._contentProps.source_id;

    if (acc[isbn]) {
      return {
        ...acc,
        [isbn]: [
          ...acc[isbn],
          withParsedContentProps
        ]
      };
    }
    return {
      ...acc,
      [isbn]: [withParsedContentProps]
    };
  }, {});
};

export const getPageNumber = (citation: ChatReferenceWithParsedContentProps): number => {
  let pageNum;
  try {
    pageNum = parseInt(citation._contentProps.page.start_page.number, 10);
  } catch (e) {
    pageNum = 1;
  }
  return pageNum;
};

export const isAdminUser = () => {
  const user = ELSTokenHelper.getUser();
  return user && user.appParams && user.appParams.adminEmailAddress;
};

export const getNormalizedEmailDomain = (userEmailDomain: string): string => {
  if (!userEmailDomain) {
    return null;
  }
  return userEmailDomain.trim().toLowerCase();
};
