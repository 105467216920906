export const osmosisVideoUrl = 'https://www.osmosis.org';

// eslint-disable-next-line max-len
export const TEMP_USER_CHAT_ID = 7777777777;
export const TEMP_BOT_CHAT_ID = 8888888888;
export const ADMIN_MODAL_ID = 'ADMIN_MODAL_ID';

export enum ChatConnectionProtocol {
  HTTP_STREAM = 'HTTP_STREAM',
  WEB_SOCKETS = 'WEB_SOCKETS',
  HTTP_POST = 'HTTP_POST',
}

export const MAX_PROMPT_LENGTH = 2500;
export const MAX_PROMPT_LENGTH_DISPLAY = '2,500';
